import { render } from 'preact';
import { MostRead } from '@vgno/widgets';
import styles from './index.module.css';

/** @param {HTMLElement} mountNode */
function stickyNode(mountNode) {
    const innerHeight = window.innerHeight;
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    entry.target.style.removeProperty('top');
                    return;
                }

                const { height } = entry.boundingClientRect;

                if (height > innerHeight) {
                    entry.target.style.top = `-${height - innerHeight}px`;
                }
            });
        },
        {
            threshold: [0.5],
        },
    );

    observer.observe(mountNode);

    return observer;
}

/** @param {Element} mountNode */
function initStickyNode(mountNode) {
    let viewObserver = null;
    const observer = new MutationObserver((entries) => {
        if (entries.length > 1) {
            viewObserver?.disconnect();
            viewObserver = stickyNode(mountNode);
        }
    });

    observer.observe(mountNode, {
        subtree: true,
        childList: true,
    });
}

export default ({ node: mountNode, device }) => {
    const { hideTitle = false, variant = 'default' } = mountNode.dataset;
    render(
        <>
            {!hideTitle && (
                <header>
                    <h2 className="title">Mest lest</h2>
                </header>
            )}
            <div className={`${styles.mostRead} vg-most-read widget`}>
                <MostRead
                    variant={variant}
                    queryParams={{
                        utm_source: 'vgfront',
                        utm_content: 'vg-most-read',
                    }}
                />
            </div>
        </>,
        mountNode,
    );

    if (!device.isMobile() && !device.isIpad()) {
        initStickyNode(mountNode);
    }
};
